import * as React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/layout'

const StoriesPage = ({ data }) => {
  return (
    <Layout pageTitle="stories">
      <ul>
        {
          data.allMdx.nodes.map((node) => (
            <article key={node.id}>
              <h2>
                <Link to={`/stories/${node.slug}`}>
                  {node.frontmatter.title}
                </Link>
              </h2>
              <p>Posted: {node.frontmatter.date}</p>
            </article>
          ))
        }
      </ul>
    </Layout>
  )
}

export const query = graphql`query {
  allMdx(
    sort: {fields: frontmatter___date, order: DESC}
    filter: {fileAbsolutePath: {regex: "/(stories)/"}}
  ) {
    nodes {
      frontmatter {
        date(formatString: "MMMM D, YYYY")
        title
      }
      id
      slug
      parent {
				... on File {
					modifiedTime(formatString:" MMMM D, YYYY")
        }
      }
    }
  }
}`

export default StoriesPage